import gtm from "../helpers/ecommerce";

const init = ()=> {
    gtm.listen('view_cart', ({ element: button })=> {
        const { gtm: data } = button.dataset
        const cart = JSON.parse(data)

        gtm .updateState({ cart })
            .viewCart()
    })
}

window.addEventListener('load', init);